body{
  overflow-x: hidden;
}
.cart {
  display: flex;
  /* overflow: hidden; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 2.5rem;
    /* position: relative;
    left: 2rem; */
  }
  
  .cartItem {
    width: 10%;
    height: 250px;
    display: flex;
  
    align-items: center;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    border-radius: 25px;
    margin: 30px;
  }
  
  .cartItem img {
    width: 100%;
    margin-left: 2rem;
  }
  
  .cartItem .description {
    width: 100%;
    font-size: 30px;
    font-style:normal;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  
  .countHandler input {
    width: 100%;
    text-align: center;
    font-weight: bolder;
  }
  
  .checkout button {
    width: 150px;
    height: 50px;
    background-color: rgb(19, 19, 19);
    color: white;
    border: none;
    border-radius: 8px;
    margin: 10px;
    cursor: pointer;
  }
  .btn{
    font-size: 1rem;
    width :3rem ;
    margin: 0 .6rem;
    color: rgb(151, 168, 236);
    background:  #000000;
    border: 2.3px solid rgb(255, 0, 0);
    border-radius: 20px;
    transition: all 300ms ease-in;
  }
  .btn:hover{
    transform: scale(1.1);
    background: hsl(117, 100%, 62%);
    border: 2px solid black;
    color: black;
  }
  .btn:active{
    transform: translateY(4px) translateX(-4px);
  }
  .btn-2:active{
    transform: translateY(4px) translateX(4px);
  }
  .int{
    width: 3rem;
    border: 3px solid black;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    color:rgb(110, 55, 0);
  }
  .sub{
    /* width: 3rem; */
    font-size: 1.8rem;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-style: normal;
    color: #430048;
    /* text-decoration: underline; */
  }
 /* media query for this section make it mobile responsive */

 @media screen and (max-width: 568px){
  
  .cart h1{
    font-size: 1.5rem;
    margin-right: 3rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  .cartItem{
    width: 18rem;
    margin-left:1rem ;
    /* margin-right: 2rem; */
  }
  .cartItem img {
    width: 6rem;
    margin-left: .8rem;
  }
  .cartItem .description {
    width: 11rem;
    font-size: 1.4rem;
    font-style:normal;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  }
  .btn{
    font-size: 1rem;
    width :2rem ;
    margin: 0 .5rem;
    color: rgb(151, 168, 236);
    background:  #000000;
    border: 2.3px solid rgb(255, 0, 0);
    border-radius: 20px;
    transition: all 300ms ease-in;
  }
  .int{
    width: 1rem;
    border-radius: 20%;
  }
  .sub{
    font-size: 1rem;
    margin-right: 3rem;
  }
  hr{
    width: 20rem;
    margin-right: 3rem;
  }
  .checkout button{
    width: 100px;
    font-size: .7rem;
  }
 }